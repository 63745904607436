import { Dispatch, SetStateAction } from "react";
import Link from "next/link";

import { cn, phoneHref } from "@/lib/utils";
import { navLinks } from "@/lib/constants";
import { companyInfoQueryResponse } from "@/lib/sanityDangerousTypes";
import { Dialog } from "@headlessui/react";
import { PhoneIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useRouter } from "next/router";

export default function MobileNavivagion({
  companyInfo,
  menuOpen,
  setMenuOpen,
}: {
  companyInfo: companyInfoQueryResponse | undefined;
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const route = useRouter();
  const { phone } = companyInfo || {};

  return (
    <Dialog
      as="div"
      className="lg:hidden"
      open={menuOpen}
      onClose={setMenuOpen}
    >
      <div className="fixed inset-0 z-10" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-10 items-end w-full px-6 py-6 overflow-y-auto bg-blue sm:max-w-sm sm:ring-1 sm:ring-white/10">
        <div>
          <div className="flex items-center justify-end my-3">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="w-8 h-8" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6 space-y-2">
                <Link
                  className={cn(
                    "block px-3 py-2 -mx-3 font-medium leading-7 text-right text-white no-underline uppercase rounded-lg text-xl font-display hover:bg-blue",
                    route.asPath === "/" ? "text-orange-400" : ""
                  )}
                  href="/"
                >
                  Home
                </Link>
                {navLinks.map((link) => (
                  <Link
                    key={link.label}
                    className={cn(
                      "block px-3 py-2 -mx-3 font-medium leading-7 text-right text-white no-underline uppercase rounded-lg text-xl font-display hover:bg-blue",
                      route.asPath === link.href ? "text-orange-400" : ""
                    )}
                    href={link.href}
                  >
                    {link.label}
                  </Link>
                ))}
                <Link
                  className="block px-3 py-2 -mx-3 text-xl font-medium leading-7 text-right uppercase rounded-lg text-orange hover:bg-gray-50/30"
                  href="/search"
                >
                  Property Search
                </Link>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 left-0 right-0 w-full text-white bg-orange">
            {phone && (
              <Link
                className="flex flex-row items-center justify-center gap-4 p-6 text-2xl text-white"
                href={phoneHref(phone)}
              >
                <PhoneIcon className="w-8 h-8" aria-hidden="true" />
                {phone}
              </Link>
            )}
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
