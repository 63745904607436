import { companyInfoQueryResponse } from "./sanityDangerousTypes";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { emailHref, phoneHref } from "./utils";

export const navLinks = [
  { href: "/brokerage", label: "Brokerage" },
  { href: "/property-management", label: "Property Management" },
  { href: "/general-contracting", label: "General Contracting" },
  { href: "/news", label: "News" },
  { href: "/careers", label: "Careers" },
  { href: "/about", label: "About" },
  { href: "/contact", label: "Contact Us" },
];

export function getNavigationFromCompany({
  companyInfo,
}: {
  companyInfo: companyInfoQueryResponse | undefined;
}) {
  const {
    address1,
    address2,
    city,
    email,
    facebook,
    instagram,
    linkedin,
    mapLinkUrl,
    phone,
    state,
    twitter,
    youtube,
    zipCode,
  } = companyInfo || {};
  const addressBottom = `${city}, ${state} ${zipCode}`;
  const addressTop = `${address1}, ${address2}`;

  const navigation = {
    contact: [
      {
        label: [phone],
        href: phoneHref(phone || ""),
        iconDef: faPhone,
      },

      {
        label: [email],
        href: emailHref(email || ""),
        iconDef: faEnvelope,
      },
      {
        label: [addressTop, addressBottom],
        href: mapLinkUrl || "",
        iconDef: faLocationDot,
      },
    ],
    solutions: [
      { name: "Property Search", href: "/search" },
      { name: "Brokerage", href: "/brokerage" },
      { name: "Property Management", href: "/property-management" },
      { name: "General Contracting", href: "/general-contracting" },
    ],
    company: [
      { name: "News", href: "/news" },
      { name: "About", href: "/about" },
      { name: "Contact", href: "/contact" },
    ],
    social: [
      {
        name: "Facebook",
        href: facebook,
        iconDef: faFacebook,
      },
      {
        name: "Youtube",
        href: youtube,
        iconDef: faYoutube,
      },
      {
        name: "Instagram",
        href: instagram,
        iconDef: faInstagram,
      },
      {
        name: "Twitter",
        href: twitter,
        iconDef: faTwitter,
      },
      {
        name: "LinkedIn",
        href: linkedin,
        iconDef: faLinkedin,
      },
    ],
  };

  return navigation;
}
